.header {
    .navbar {
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;

        @include media-breakpoint-down(md) {
            background-color: #5d0049;
            position: unset;
        }
    }
}

.hero-desktop {
    @include media-breakpoint-down(sm) {
        display: none;
    }

    .hero-background {
        height: 100vh;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;

        .caption {
            h1 {
                color: #fff;
                font-size: 45px;
                font-weight: 700;
                text-transform: uppercase;
            }

            p {
                color: #fff;
                font-size: 20px;
                font-weight: 400;
            }
        }
    }
}

.hero-mobile {
    display: none;

    @include media-breakpoint-down(sm) {
        display: block;
    }

    .hero-background {
        height: 100vh;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;

        .caption {
            padding-top: 20px;

            h1 {
                color: #fff;
                font-size: 30px;
                font-weight: 700;
                text-transform: uppercase;
            }

            p {
                color: #fff;
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
}

.section-1-desktop {
    @include media-breakpoint-down(sm) {
        display: none;
    }

    .section-1-background {
        height: 100vh;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;

        .caption {
            p {
                color: #fff;
                font-size: 20px;
                font-weight: 300;

                span {
                    color: #00A69C;
                }
            }

            .button {
                .card{
                    background-color: unset;
                    border: unset;
                    .btn {
                        color: #fff;
                        font-size: 25px;
                        font-weight: 400;
                        padding-left: 20px;
                        padding-right: 20px;
                        transition: all 0.5 ease;
    
                        &.enfection {
                            margin-right: 10px;
                        }
    
                        span {
                            color: #EF6325;
                        }
    
                        &:hover {
                            border: 1px solid #EF6325;
                        }
                    }

                    p{
                        color: #fff;
                        font-size: 16px;
                        font-weight: 300;
                        text-align: center;
                    }
                }
            }
        }


    }
}

.section-1-mobile {
    display: none;

    @include media-breakpoint-down(sm) {
        display: block;
    }

    .section-1-background {
        height: 100vh;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;

        .caption {
            padding-top: 40px;

            p {
                color: #fff;
                font-size: 18px;
                font-weight: 500;
                text-align: center;

                span {
                    color: #00A69C;
                }
            }

            .button {
                text-align: center;

                .card{
                    background-color: unset;
                    border: unset;
                    .btn {
                        color: #fff;
                        font-size: 20px;
                        font-weight: 400;
                        padding-left: 10px;
                        padding-right: 10px;
    
                        &.enfection {
                            margin-right: 10px;
                        }
    
                        span {
                            color: #EF6325;
                        }
    
                        &:hover {
                            border: 1px solid #EF6325;
                        }
                    }

                    p{
                        color: #fff;
                        font-size: 14px;
                        font-weight: 500;
                        text-align: center;
                    }
                }


            }
        }
    }
}

.section-2 {
    background-color: #05a59d;
    // height: 85vh;

    .row {
        align-items: center;
        padding-bottom: 60px;

        .description {
            padding: 30px 0px;

            p {
                color: #fff;
                font-size: 20px;

                @include media-breakpoint-down(md) {
                    text-align: center;
                }
            }
        }

        .card {
            background-color: #05a59d;
            border: unset;

            .counter-box {
                text-align: center;

                span {
                    color: #fff;
                    font-size: 70px;
                    font-weight: 700;

                    @include media-breakpoint-down(lg) {
                        font-size: 60px;
                    }

                }

                p {
                    color: #fff;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }
    }
}

.section-3 {
    padding: 40px 0px;

    h2 {
        color: #000;
        font-size: 35px;
        font-weight: 500;
        text-align: center;
        padding-bottom: 40px;

        @include media-breakpoint-down(lg) {
            font-size: 20px;
        }
    }

    .network-slider {
        .owl-stage {
            display: flex;
            align-items: center;

            .item {
                img {
                    width: 80%;
                    margin: 0 auto;
                }
            }
        }
    }

    .hide-md {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .show-md {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }
}


.section-5 {
    padding: 40px 0px;

    .row {
        align-items: center;

        .st7 {
            font-family: "Poppins", sans-serif;
        }

        .test-hilight {
            fill: #00A69C;
            font-weight: 600;
        }

        .heading {
            h3 {
                color: #000;
                font-size: 30px;
                font-weight: 600;

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    text-align: center;
                }

                span {
                    color: #00A69C;
                }
            }

            .line {
                background-color: #00A69C;
                width: 60%;
                height: 5px;
                border-radius: 10px;

                @include media-breakpoint-down(md) {
                    margin: 0 auto;
                }
            }

            .description {
                padding-top: 30px;

                p {
                    color: #000;
                    font-size: 16px;
                    font-weight: 400;

                    @include media-breakpoint-down(md) {
                        text-align: center;
                    }
                }
            }
        }

        .desktop-wrap {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .mobile-wrap {
            display: none;

            @include media-breakpoint-down(md) {
                display: block;
            }

            .owl-theme .owl-dots .owl-dot span {
                width: 8px;
                height: 4px;
                margin: 5px 2px;
            }
        }

        .content {
            padding-top: 30px;

            h4 {
                color: #000;
                font-size: 25px;
                font-weight: 600;

                @include media-breakpoint-down(md) {
                    font-size: 18px;
                    text-align: center;
                }
            }

            p {
                color: #000;
                font-size: 16px;
                font-weight: 400;

                @include media-breakpoint-down(md) {
                    text-align: center;
                }
            }
        }
    }

}

.section-6 {
    padding: 60px 0px;

    @include media-breakpoint-down(md) {
        padding: 30px 0px;
    }

    .inner-box {
        -webkit-box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.18);
        -moz-box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.18);
        box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.18);
        border-radius: 20px;

        h2 {
            color: #000;
            font-size: 35px;
            font-weight: 600;
            text-align: center;
            padding-top: 30px;

            @include media-breakpoint-down(lg) {
                font-size: 20px;
            }

            span {
                color: #00A69C;
            }
        }

        .image-grid {
            padding: 30px 30px;

            .row {
                align-items: center;
            }

            .image {
                text-align: center;

                img {
                    width: 50%;
                    margin: 0 auto;
                }
            }
        }
    }
}

.section-7 {
    @media (max-width: 768px) {
        display: none;
    }

    @media (min-width: 768px) {
        display: block;
    }

    .row {
        overflow: hidden;

        .box-content {
            min-height: 360px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            align-items: flex-end;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;

            &:hover {
                -webkit-transform: scale(1.02);
                transform: scale(1.02);
            }

            .details {
                color: #fff;
                padding-bottom: 20px;

                h6 {
                    text-transform: uppercase;
                    margin-bottom: 0px;
                }

                p {
                    font-size: 14px;
                    font-weight: 300;
                    margin-bottom: 5px;
                }

                a {
                    color: #fff;
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 300;
                }
            }
        }
    }
}

.section-7-mobile {
    overflow: hidden;

    @media (max-width: 768px) {
        display: block;
    }

    @media (min-width: 768px) {
        display: none;
    }

    .box-content {
        text-align: left !important;
        min-height: 400px;

        @include media-breakpoint-down(sm) {
            min-height: 175px;
        }

        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;

        &:hover {
            -webkit-transform: scale(1.02);
            transform: scale(1.02);
        }

        .details {
            color: #fff;
            padding: 0 10px 20px 10px;

            h6 {
                text-transform: uppercase;
                margin-bottom: 0px;
                font-size: 20px !important;
            }

            p {
                font-size: 12px;
                font-weight: 300;
                margin-bottom: 5px;
            }

            a {
                color: #fff;
                text-decoration: none;
                font-size: 12px;
                font-weight: 300;
            }
        }
    }
}

.section-8 {
    @include media-breakpoint-down(md) {
        display: none;
    }

    .background-image {
        height: 100vh;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        .description {
            padding-top: 80px;

            .title {
                color: #fff;
                font-size: 50px;
                font-weight: 600;
                margin-bottom: 5px;
            }

            .line {
                width: 50%;
                padding-bottom: 60px;
            }

            .text {
                color: #fff;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}

.section-8-mb {
    display: none;

    @include media-breakpoint-down(md) {
        display: block;
    }

    .background-image {
        height: 730px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        .description {
            padding-top: 30px;

            .title {
                color: #fff;
                font-size: 30px;
                font-weight: 600;
                margin-bottom: 5px;
            }

            .line {
                width: 50%;
                padding-bottom: 30px;
            }

            .text {
                color: #fff;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}

.contact-from {
    padding: 60px 0px;
    background-color: #1D1D1F;

    .caption {
        h2 {
            color: #fff;
            font-size: 35px;
            font-weight: 500;
            padding-bottom: 40px;
        }
    }

    .form {
        .form-label {
            color: #fff;
            font-size: 18px;
            font-weight: 300;
        }

        .form-control {
            border: 1px solid #fff;
            background-color: #1D1D1F;
            color: #F5F5F5;

            &:focus {
                box-shadow: none;
            }
        }

        .btn-submit {
            color: #F5F5F5;
            border: 1px solid #F5F5F5;
            padding: 10px 15px;

            &:hover {
                border: 1px solid #EF6325;
            }
        }
    }
}

.footer {
    background-color: #1D1D1F;
    padding: 60px 0px;

    @include media-breakpoint-down(lg) {
        padding-bottom: 80px;
    }

    .footer--inner {
        .footer--col-1 {
            .col--inner {
                @include media-breakpoint-down(lg) {
                    padding-bottom: 30px;
                }

                .card {
                    background-color: transparent;
                    border: unset;

                    .title {
                        color: #F5F5F5;
                        font-size: 20px;
                        font-weight: 500;
                    }

                    .address {
                        p {
                            color: #F5F5F5;
                            font-size: 16px;
                            font-weight: 100;
                        }
                    }

                    .menu-link {
                        margin-bottom: 5px;

                        a {
                            color: #F5F5F5;
                            font-size: 16px;
                            font-weight: 100;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .footer--col-2 {
            .social-icons {
                display: flex;
                align-items: flex-start;
                justify-content: end;

                @include media-breakpoint-down(lg) {
                    justify-content: center;
                }

                p {
                    margin-bottom: 0px;
                    padding: 0 15px;

                    a {
                        color: #F5F5F5;

                        svg {
                            stroke: #F5F5F5;
                            transition: stroke 0.8s ease, color 0.8s ease;
                        }

                        &:hover {
                            svg {
                                stroke: #00A69C;
                            }
                        }

                        i {
                            font-size: 20px;
                            font-weight: 100;
                        }

                    }
                }
            }
        }
    }

    hr {
        background-color: #F5F5F5;
        border: 0;
        opacity: 1;
        margin: 40px 0px;

        @include media-breakpoint-down(lg) {
            margin: 20px 0px;
        }
    }

    .copyright {
        text-align: center;

        p {
            color: #F5F5F5;
            font-size: 12px;
            font-weight: 100;
            margin-bottom: 0px;

            a {
                color: #F5F5F5;
                text-decoration: none;
            }

            sup {
                font-size: 15px;
            }
        }
    }
}