@import './node_modules/bootstrap/scss/bootstrap.scss'; // <-------- Loads Bootstrap4
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './node_modules/slick-carousel/slick/slick.scss';
@import './node_modules/owl.carousel/src/scss/owl.carousel.scss';// <-------- Loads Owl Carousel
@import './node_modules/owl.carousel/src/scss/owl.theme.default.scss'; // <-------- Loads Owl Carousel

* {
    font-family: "Poppins", sans-serif;
}


@import "theme/index";